import { useNavigate } from "react-router-dom";
import { HeroImage } from "../../constants/assets";

const HeroSection = () => {
  const navigate = useNavigate();

  return (
    <section className="landingHeader pt-24 pb-20 md:py-16">
      <div className="b-container-big md:py-[40px]">
        <div className="flex justify-center text-center hero-h1 py-2 text-[20px] md:text-[40px] items-center">
          Unlocking the Future of Blockchain
        </div>
        <div className="flex flex-col-reverse md:flex-row items-center justify-center md:justify-between">
          <div className="flex flex-col items-start  ">
            <div className="lydiaTitleContainer !text-[64px] !font-bold !sm:text-2xl text-center md:text-start !mb-0 ">
              <div className="text-[#7D011C]"> Lydia Coin </div>
              <div className="text-[#000]">The Future of Stable Digital Currency</div>
            </div>
            <h2 className="text-subColor opacity-70 md:mt-3 mb-8 md:w-3/4 text-center md:text-start">
              Experience stability, security, and transparency in the world of
              digital finance.
            </h2>
            <div
              className="flex flex-col md:flex-row justify-center md:justify-start flex-wrap items-center w-full z-10"
              data-v-41a2d66f=""
            >
              <button
                className="btnPrimary w-max btnDefault max-h-[48px] flex flex-row items-center justify-center mb-3 md:mb-0 md:mr-2"
                data-v-41a2d66f=""
                onClick={()=>navigate('wallet')}
              >
                <span className=" text-base leading-button">Lydia Wallet</span>
              </button>
              <button
                className="btnSecondary w-max  btnDefault max-h-[48px] flex flex-row items-center justify-center"
                data-v-41a2d66f=""
                onClick={()=>navigate('use-cases')}
              >
                <span className="text-base leading-button">Learn More</span>
              </button>
            </div>
          </div>
          <div className="landingHeaderImg">
            <img className="scene" src={HeroImage} alt="landing" />
          </div>
        </div>
      </div>
    </section>
  );
};
export default HeroSection;
