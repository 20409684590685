import FaqBox from "../../component/faqBox/FaqBox";
import HelmetComponent from "../../component/Reusable/HelmetComponent";

const LydiaData = {
  title: "About Lydia",
  question: [
    {
      question: "What is Lydia Coin (LYD)?",
      answer:
        "Lydia Coin (LYD) is a digital asset designed to maintain a stable value, making it an ideal choice for users seeking a reliable cryptocurrency. It combines the benefits of blockchain technology with the stability of traditional currencies, enabling seamless transactions, hedging against market volatility, and facilitating efficient remittances.",
    },
    {
      question: "What is the background of Lydia Coin?",
      answer:
        "Lydia Coin (LYD) was created in response to the growing demand for a stable digital currency in an increasingly volatile cryptocurrency market. By leveraging advanced blockchain technology, Lydia aims to provide individuals and businesses with a secure and user-friendly way to conduct transactions without the uncertainty typically associated with cryptocurrencies.",
    },
    {
      question: "What’s the origin of Lydia's name?",
      answer:
        "The name 'Lydia' is inspired by historical significance and stability, symbolizing a trusted medium of exchange in ancient times. The choice reflects the coin's purpose: to create a modern financial tool that embodies reliability and security in the digital age.",
    },
    {
      question: "What is the business model of Lydia Coin? How does it generate revenue for development?",
      answer:
        "Lydia Coin (LYD) operates on a model that involves transaction fees, which are minimal compared to traditional banking systems. Revenue is also generated through partnerships with businesses and financial institutions, enabling them to integrate Lydia into their payment solutions. This model supports ongoing development and enhancements of the platform.",
    },
    {
      question: "How does Lydia manage its reserves?",
      answer:
        "Lydia Coin (LYD) is backed by a carefully managed reserve of assets to ensure its stability. The reserves consist of a diversified portfolio of fiat currencies and other secure assets. Regular audits and transparency measures are in place to reassure users that their investments are backed by real value.",
    },
    {
      question: "How does Lydia ensure regulatory compliance?",
      answer:
        "Lydia Coin (LYD) adheres to local and international regulations to maintain transparency and trust. The team actively engages with regulatory bodies and implements robust compliance measures, including regular audits and reporting. This commitment ensures that Lydia operates within the legal framework while safeguarding users’ interests.",
    },
  ]
};

const WalletData = {
  title: "About Wallet",
  question: [
    {
      question: "What is Lydia Coin Wallet?",
      answer:
        "Lydia Coin Wallet is a secure digital wallet designed to store, manage, and transact with Lydia Coin. It provides users with an easy-to-use interface for handling their digital assets while ensuring top-notch security measures to protect their funds.",
    },
    {
      question: "What are the defining features of your wallet?",
      answer:
        "The Lydia Wallet offers several key features, including instant transactions, multi-platform accessibility, user-friendly navigation, and transaction tracking, making it suitable for both beginners and experienced users.",
    },
    {
      question: "How can I use the Lydia Wallet?",
      answer:
        "Using the Lydia Wallet is straightforward. After downloading the app, you can create a wallet and fund it by purchasing Lydia Coin through the wallet itself. Once your wallet is set up, you can easily send, receive, and manage your Lydia assets anytime, anywhere.",
    },
    {
      question: "Is it safe to use?",
      answer:
        "Yes, the Lydia Wallet is designed with security as a top priority. It employs cryptographic encryption, noncustodial storage, decentralization, and in-app features like Face ID and password lock. Users have complete control over their private keys, enhancing security further.",
    },
    {
      question: "Can I recover an address from another wallet?",
      answer:
        "Yes, the Lydia Wallet supports the import of addresses from other wallets. Users can recover their funds by entering the corresponding private keys or recovery phrases, allowing for a seamless transition to the Lydia Wallet.",
    },
    {
      question: "Are you planning to add other cryptocurrencies to the Lydia Wallet?",
      answer:
        "Currently, the focus is on Lydia Coin; however, the development team is exploring the possibility of adding other popular cryptocurrencies in the future to enhance user experience and provide more flexibility for managing diverse digital assets.",
    },
  ]

};
export const Faq: React.FC = (): JSX.Element => {
  return (
    <>
      <HelmetComponent
        title="Lydia Coin - FAQ"
        description="Learn about Lydia Coin, a secure and stable digital currency."
        keywords="Lydia Coin, Cryptocurrency, Stable Coin, Digital Assets"
        ogTitle="Lydia Coin FAQ"
        ogDescription="Find answers to common questions about Lydia Coin."
        ogImage="https://lydia-coin-landing-page.vercel.app"
        ogUrl="https://lydia-coin-landing-page.vercel.app"
        twitterTitle="Lydia Coin FAQ"
        twitterDescription="Find answers to common questions about Lydia Coin."
        twitterImage="https://lydia-coin-landing-page.vercel.app"
      />

      <div className="font-inter flex-grow">
        <section
          className="pt-28 bg-grayBackground pb-15 md:pb-20 mainBlock"
          id="faqHeader"
        >
          <div className="b-container-big">
            <div>
              {/* <h1 className="text-primary text-sm uppercase">faq</h1> */}
              <h2 className="lydiaTitleContainer">
                Frequently Asked Questions- FAQs
              </h2>
            </div>
          </div>
        </section>
        <section className="bg-white"  >
          <div className="lg:px-4 lg:w-full lg:max-w-[1280px] lg:mx-auto pb-[80px]">
            <div className="md:mx-0 flex flex-col gap-10">
              <FaqBox data={LydiaData} />
              {/* <FaqBox id="aboutEurs" data={EursData} /> */}
              <FaqBox data={WalletData} />
            </div>
          </div>
        </section>
        {/*]*/}
      </div>
    </>
  );
};
