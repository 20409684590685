import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  LydiaAction1Light,
  LydiaAction2Light,
  LydiaAction3Light,
  LydiaAction4Light,
  LydiaCoin1,
  // LydiaCoin1bg,
  LydiaWork1Light,
  LydiaWork2Light,
  LydiaWork3Light,
} from "../../constants/assets";

import HeroSection from "./HeroSection";
import { BlogCard } from "../../component/card/BlogCard";
import JoinLydia from "../../constants/JoinLydia";
import HelmetComponent from "../../component/Reusable/HelmetComponent";
import axiosInstance from "../../lib/axiosInstance";
import { useTotalSupplyContext } from "../../context/totalSupplyContext";
import { formatBigInt } from "../../component/Reusable/reusable";
import "./Home.css";

export interface BlogData {
  blogTitle?: string;
  blogDate?: string;
  blogSubDescription?: string;
  blogDescription?: string;
  feature_image?: string;
  feature_image_alt?: string;
  category?: string;
  title?: string;
  html?: string;
  slug?: string;
  id?: string;
  primary_tag?: object;
  excerpt?: string;
  published_at: string;
}

const Home = () => {
  const { totalSupply, loading, error: supplyError } = useTotalSupplyContext();
  const navigate = useNavigate();
  const [blogs, setBlogs] = useState<BlogData[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [count, setCount] = useState<string>("0");
  const countRef = useRef<HTMLHeadingElement | null>(null); // Create a ref for the count element

  const TotalSupply = BigInt(totalSupply ? totalSupply : 0);

  useEffect(() => {
    const handleScroll = () => {
      if (countRef.current) {
        const rect = countRef.current.getBoundingClientRect();
        const isInView = rect.top >= 0 && rect.bottom <= window.innerHeight;

        if (isInView && TotalSupply > BigInt(0)) {
          const duration = 10000; // Duration for the counting animation
          const steps = BigInt(duration / 100); // Number of steps based on duration and interval
          let increment = TotalSupply / steps; // Calculate increment
          if (increment === BigInt(0)) {
            increment = BigInt(1); // Ensure increment is at least 1
          }
          const interval = setInterval(() => {
            setCount((prevCount) => {
              const newCount = BigInt(prevCount) + increment;
              if (newCount < TotalSupply) {
                return newCount.toString(); // Convert back to string for state
              }
              clearInterval(interval); // Clear the interval once count reaches totalSupply
              return TotalSupply.toString(); // Set to totalSupply when done
            });
          }, 100); // Update every 100ms

          return () => clearInterval(interval); // Cleanup on unmount
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [totalSupply]);

  const LydiaData = [
    {
      title: "Acquire Lydia Coin",
      description:
        "Invest in Lydia Coin by purchasing it through your Lydia Wallet, which provides a simple and straightforward process for acquiring cryptocurrency.",
      imageSrc: LydiaWork1Light,
    },
    {
      title: "Store Safely",
      description:
        "Once purchased, store your Lydia Coin in a compatible digital wallet that supports secure encryption, ensuring your assets are protected from unauthorized access.",
      imageSrc: LydiaWork2Light,
    },
    {
      title: "Make Transactions",
      description:
        "Use Lydia Coin for a variety of purposes, including payments, transfers, or as a store of value, making it a versatile option for both everyday use and long-term holding.",
      imageSrc: LydiaWork3Light,
    },
    {
      title: "Manage Your Portfolio",
      description:
        "Track and manage your Lydia Coin holdings directly from your Lydia Wallet, which offers real-time updates on your balance and transaction history.",
      imageSrc: LydiaAction1Light,
    },
    {
      title: "Secure Transactions",
      description:
        "Benefit from blockchain technology to ensure that all transactions involving Lydia Coin are verified and secure, reducing the risk of fraud and errors.",
      imageSrc: LydiaAction2Light,
    },
    {
      title: "Access from Multiple Devices",
      description:
        "Lydia Coin can be accessed from various devices, thanks to its compatibility with different digital wallets and platforms, allowing for convenient management on the go.",
      imageSrc: LydiaAction3Light,
    },
    {
      title: "Decentralized Operations",
      description:
        "Lydia Coin operates on a decentralized blockchain, providing transparency and control over your assets without the need for intermediaries.",
      imageSrc: LydiaAction4Light,
    },
  ];


  const LydiaActionData = [
    {
      title: "Inheritance",
      description:
        "Ensure your assets are safeguarded with Lydia's inheritance feature, allowing you to designate a nominee who will receive wallet access if something happens to you.",
      imageSrc: LydiaAction1Light,
    },
    {
      title: "Savings",
      description:
        "Protect your wealth from inflation by storing it in Lydia Coin, which maintains value stability and offers a secure savings option.",
      imageSrc: LydiaAction2Light,
    },
    {
      title: "High-Risk Investments",
      description: "Use Lydia Wallet to invest in Lydia Coins, suitable for higher-risk investments with the potential for greater returns.",
      imageSrc: LydiaAction3Light,
    },
    {
      title: "Low-Risk Investments",
      description: "For more conservative investment approaches, use Lydia Wallet to purchase Lydia Coins, offering a safer alternative with lower risk.",
      imageSrc: LydiaAction4Light,
    },
  ];

  const fetchBlogs = async () => {
    try {
      const apiKey = process.env.REACT_APP_API_KEY;
      const apiTag = process.env.REACT_APP_API_TAG;
      if (!apiKey || !apiTag) {
        setError("API key or tag is missing");
        console.error("api key or tag missing ");
      }
      const response = await axiosInstance.get(
        `/?key=${process.env.REACT_APP_API_KEY}${process.env.REACT_APP_API_TAG}&limit=3`
      );
      setBlogs(response?.data?.posts);
    } catch (error) {
      setError("Failed to fetch blog data");
    }
  };

  useEffect(() => {
    fetchBlogs();
  }, []);

  return (
    <>
      <HelmetComponent
        title="Lydia Coin - Home"
        description="Welcome to Lydia Coin, a secure and stable digital currency."
        keywords="Lydia Coin, Cryptocurrency, Stable Coin, Digital Assets"
        ogTitle="Lydia Coin - Home"
        ogDescription="Welcome to Lydia Coin, a secure and stable digital currency."
        ogImage="https://lydia-coin-landing-page.vercel.appg"
        ogUrl="https://lydia-coin-landing-page.vercel.app"
        twitterTitle="Lydia Coin - Home"
        twitterDescription="Welcome to Lydia Coin, a secure and stable digital currency."
        twitterImage="https://lydia-coin-landing-page.vercel.app"
      />

      {/* section one start */}
      <HeroSection />
      {/* section one end */}
      {/* section two start */}
      {/* <section className="b-container-big items-start my-[32px] md:my-[64px] lg:my-[100px]"> */}
      <section className="b-container-big items-start my-[10px] md:my-[30px] lg:my-[50px]">
        <div className="flex flex-col md:flex-row justify-center md:justify-between items-center !px-0">
          <div className="landingHeaderImg flex-1">
            <img
              className="scene"
              src={LydiaCoin1}
              alt="landing"
              width={400}
              height={400}
            />
          </div>
          <div className="flex flex-col flex-1 items-start mt-4 md:mt-0">
            <h3 className="w-full lydiaSubtitleContainer flex flex-col">
              What is Lydia Coin?
            </h3>
            <h3 className="w-full text-subColor mt-4 md:mt-4">
              Lydia Coin is a blockchain-based stable coin designed to
              provide a secure and stable digital currency option for users
              worldwide. All Lydia Coins are pegged at 1-to-1 with a
              matching fiat currency and are backed 100% by Lydia’s Reserves.
            </h3>
            <h3 className="w-full text-subColor mt-4 md:mt-4">
              With Lydia Coin, you can enjoy the benefits of digital
              currency without the risks associated with price fluctuations,
              making it ideal for payments, savings, and investments.
            </h3>
          </div>
        </div>
      </section>
      {/* section two end */}
      {/* section three start */}
      <section className="b-container-big items-start my-[32px] md:my-[64px] lg:my-[100px]">
        <div className="flex flex-col md:flex-row justify-center md:justify-between items-center !px-0">
          <div className="flex flex-col flex-1 items-start mt-4 md:mt-0">
            <h3 className="w-full lydiaSubtitleContainer flex flex-col">
              Digital Asset Transparency
            </h3>
            <h3 className="w-full text-subColor mt-4 md:mt-4">
              To ensure that investors remain confident in the stability of their digital assets, we maintain an incomparable degree of reserve transparency. This guarantees that all digital assets are fully backed by the corresponding collateral.
            </h3>
          </div>
          <div className="flex flex-col flex-1 items-center md:items-end mt-4 md:mt-0">
            <h3 className="text-subColor mt-4 text-[16px]">
              Lydia Coin currently in circulation
            </h3>
            <h1
              ref={countRef} // Attach the ref to the count element
              className="blueSeccondary text-[30px] md:text-[70px] !m-0 !p-0 text-center md:text-left break-all"
            >
              {count ? formatBigInt(BigInt(count)) : "0"}
              {" "}
              <span className="whitespace-nowrap">LYD</span>
            </h1>
          </div>
        </div>
      </section>
      {/* section three end */}
      {/* section four start */}
      <div className="b-container-big py-16">
        <div className="lydiaSubtitleContainer mb-6">
          Powered by Advanced Blockchain Technology
        </div>
        <div className="flex flex-col md:flex-row justify-start items-start my-8 flex-wrap">
          <div className="flex flex-col flex-1 px-6 border-l-2 border-gray-300 pb-4 mb-6">
            <div className="text-subColor text-justify">
              Lydia Coin uses the power of advanced blockchain technology, offering a secure and transparent digital currency. Built upon smart contracts and decentralized governance, our platform guarantees the safety and verifiability of every transaction.Lydia Coin uses the power of advanced blockchain technology, offering a secure and transparent digital currency. Built upon smart contracts and decentralized governance, our platform guarantees the safety and verifiability of every transaction.
            </div>
          </div>
          <div className="flex flex-col flex-1 px-6 border-l-2 border-gray-300 pb-4">
            <div className="text-subColor text-justify">
              Regular audits and collaborations with renowned blockchain security firms further enhance Lydia Coin's security. Our unwavering commitment to transparency empowers you to place your trust in Lydia Coin for all your digital financial endeavors.
            </div>
          </div>
        </div>
      </div>
      {/* section four end */}
      {/* section five start */}
      <div className="b-container-big md:last:py-16">
        <div className="flex flex-col flex-1 pb-4">
          <div className="lydiaSubtitleContainer">How Lydia Works?</div>
          <p className="text-subColor mt-2 ">
            Lydia Coin is designed for ease of use and security, offering a streamlined experience for acquiring, storing, and using digital currency. Here's a detailed look at how Lydia Coin operates:
          </p>
        </div>
        <div
          className="w-full mt-4 grid grid-cols-1 md:grid-cols-3 gap-8"
          data-id="key-benefits"
          data-element_type="container"
        >
          {LydiaData.map((lydia, index) => (
            <div
              key={index}
              className="flex flex-row items-start bg-white md:p-4 rounded-lg"
            >
              <img
                src={lydia.imageSrc}
                alt={lydia.title}
                className="w-10 h-10 mx-4"
              />
              <div className="flex flex-col">
                <h3 className="text-lg font-semibold text-[#7D011C] mb-2">
                  {lydia.title}
                </h3>
                <p className="text-subColor-1">{lydia.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
      {/* section five end */}

      {/* section six start */}
      <div className="pt-24 pb-8 b-container-big">
        <div className="lydiaSubtitleContainer">Lydia in Action</div>
        <div
          className="w-full grid mt-16 grid-cols-1 md:grid-cols-4 gap-8"
          data-id="key-benefits"
          data-element_type="container"
        >
          {LydiaActionData.map((lydia, index) => (
            <div
              key={index}
              className="flex flex-row items-start bg-white md:p-4 rounded-lg"
            >
              <img
                src={lydia.imageSrc}
                alt={lydia.title}
                className="w-10 h-10 mx-4"
              />
              <div className="flex flex-col">
                <h3 className="text-lg font-semibold text-[#7D011C] mb-2">
                  {lydia.title}
                </h3>
                <p className="text-subColor-1 text-justify">{lydia.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
      {/* section six end */}

      {/* section seven start */}
      <div className="b-container-big md:mt-8">
        <div className="lydiaSubtitleContainer">Latest Blogs</div>
        <section className="bg-grayBackground font-inter pt-[64px] md:pb-[56px] pb-[64px] md:pt-[60px]">
          <div className="eurs-container-blog grid-cols-6 md:grid-cols-6 md:gap-8 flex md:flex-row flex-col items-start font-inter w-full">
            {blogs?.map((item, index) => (
              <BlogCard key={index} data={item} />
            ))}
          </div>
          <div className="w-full flex justify-center mt-4">
            <button
              className="btnSecondary btnDefault max-h-[48px] flex flex-row items-center justify-center"
              onClick={() => {
                navigate("/blog");
              }}
            >
              <span className="text-base leading-button">Read All Blogs</span>
            </button>
          </div>
        </section>
      </div>

      <JoinLydia />
    </>
  );
};
export default Home;
