import React, { useRef } from "react";
import {
  CheckmarkIcon,
  LydiaCoin1,
  Network,
  PartnerBenefits1Light,
  PartnerBenefits2Light,
  PartnerBenefits3Light,
  PartnerBenefits4Light,
} from "../../constants/assets";

import PartnerForm from "./PartnerForm";
import HelmetComponent from "../../component/Reusable/HelmetComponent";

import "./Partner.css";

// Define the key benefits data
const keyBenefits = [
  {
    title: "Growth",
    description:
      "Drive business with demand generation activities around the partner program",
    imageSrc: PartnerBenefits1Light,
  },
  {
    title: "Visibility",
    description:
      "Participating in the Lydia partner program puts your firm in front of the biggest names in financial services, crypto and more",
    imageSrc: PartnerBenefits2Light,
  },
  {
    title: "Promotion",
    description:
      "Unlock co-brand and marketing opportunities with Lydia and our partner network.",
    imageSrc: PartnerBenefits3Light,
  },
  {
    title: "Innovation",
    description:
      "Build innovative blockchain-based solutions into your products and services using the Lydia platform",
    imageSrc: PartnerBenefits4Light,
  },
];

const networkData = [
  {
    title: "Clients",
    description:
      "The biggest consulting firms in the industry partner with Paxos to provide cutting-edge expertise to help their clients navigate the world of blockchain, crypto assets and tokenization",
    imageSrc: Network,
  },
  {
    title: "Licensed Partner",
    description:
      "High-quality builders partner with Paxos to use blockchain technology to transform their business, develop new solutions, launch new products and more",
    imageSrc: Network,
  },
  {
    title: "Lydia",
    description:
      "The best independent software vendors (ISVs) in the ecosystem work with Paxos to develop, build and co-sell blockchain solutions",
    imageSrc: Network,
  },
];

// Dummy list of lorem ipsum items
const loremItems = Array(5).fill("Lorem Ipsum");

const Partners: React.FC = (): JSX.Element => {
  const partnerFormRef = useRef<HTMLDivElement | null>(null);

  // Step 2: Define a function to scroll to the form
  const scrollToForm = () => {
    if (partnerFormRef.current) {
      partnerFormRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <>
      <HelmetComponent
        title="Lydia Coin - Partners"
        description="Meet the partners who collaborate with Lydia Coin."
        keywords="Lydia Coin, Partners, Collaboration, Cryptocurrency"
        ogTitle="Lydia Coin - Partners"
        ogDescription="Meet the partners who collaborate with Lydia Coin."
        ogImage="https://lydia-coin-landing-page.vercel.app"
        ogUrl="https://lydia-coin-landing-page.vercel.app"
        twitterTitle="Lydia Coin - Partners"
        twitterDescription="Meet the partners who collaborate with Lydia Coin."
        twitterImage="https://lydia-coin-landing-page.vercel.app"
      />
      <div
        // bg-gradient-to-r from-blue-500 to-purple-500 p-8
        className="pt-28 py-8 flex flex-col items-center justify-center w-full b-container-big"
        data-id="8c03244"
        data-element_type="container"
      >
        {/* Heading Section */}
        <div
          className="w-full text-center "
          data-id="79d430a"
          data-element_type="widget"
        >
          <h1 className="lydiaTitleContainer !p-0">Lydia Partner Program</h1>
        </div>

        {/* Description Section */}
        <div
          className="w-full md:px-2 md:w-3/4 text-center"
          data-id="d2cfea1"
          data-element_type="widget"
        >
          <p className="text-subColor py-8">
            Lydia collaborates with a variety of partners to help the world's
            top financial institutions and businesses leverage blockchain
            technology in a regulated, secure, and scalable manner.
          </p>
        </div>

        {/* Button Section */}
        <div
          className="flex flex-row flex-wrap justify-center items-center w-full z-10"
          data-v-41a2d66f=""
        >
          <button
            className="btnPrimary w-max btnDefault max-h-[48px] flex flex-row items-center justify-center mb-3 md:mb-0 md:mr-2"
            data-v-41a2d66f=""
          >
            <span className=" text-base leading-button" onClick={scrollToForm}>Become a Partner</span>
          </button>
        </div>
      </div>
      {/* Key Benefits Section */}
      <div className="pt-8 md:pt-24 pb-8 b-container-big">
        <div className="flex flex-col md:b-container-big mb-6">
          <div className="lydiaSubtitleContainer mb-6">
            Unlock Key Benefits of the Partner Program
          </div>
        </div>
        <div
          className="w-full grid grid-cols-1 md:grid-cols-4 gap-8"
          data-id="key-benefits"
          data-element_type="container"
        >
          {keyBenefits.map((benefit, index) => (
            <div
              key={index}
              className="flex flex-col items-start bg-white md:p-4 rounded-lg"
            >
              <img
                src={benefit.imageSrc}
                alt={benefit.title}
                className="w-16 h-16 mb-4"
              />
              <h3 className="text-lg font-semibold text-[#7D011C] mb-2">
                {benefit.title}
              </h3>
              <p className="text-subColor-1 ">{benefit.description}</p>
            </div>
          ))}
        </div>
      </div>

      {/* img and details */}
      <div className="b-container-big py-16">
        <div className="py-8 flex flex-row md:flex-row justify-between items-center flex-wrap">
          <div className="flex md:flex-row items-center flex-col">
            <div className="flex flex-col flex-1 pb-4 w-full">
              <div className="lydiaTitleContainer mb-6">
                Lydia Network creates a win-win solution for all participants
              </div>
            </div>
            <div className=" shieldImage flex flex-col flex-1 items-center pb-4 min-w-64">
              <img
                className="w-96 h-96"
                src={LydiaCoin1}
                alt="Regulatory-First-Approach"
              />
            </div>
          </div>
          <div
            className="w-full grid grid-cols-1 mt-6 md:grid-cols-3 gap-8"
            data-id="key-benefits"
            data-element_type="container"
          >
            {networkData.map((network, index) => (
              <div
                key={index}
                className="flex flex-col items-start bg-white md:p-4 rounded-lg"
              >
                <div className="flex">
                  <img
                    src={network.imageSrc}
                    alt={network.title}
                    className="w-10 h-10 mr-4"
                  />
                  <h3 className="text-[28px] font-semibold text-[#7D011C] mb-2">
                    {network.title}
                  </h3>
                </div>
                <ul className="list-disc">
                  {loremItems.map((item, index) => (
                    <li
                      key={index}
                      className="text-subColor-1 text-left pl-3 flex flex-row items-center"
                      style={{ listStyleType: "none" }}
                    >
                      <img
                        src={CheckmarkIcon} // Use your imported checkmark icon
                        alt="Checkmark"
                        className="w-4 h-4 mr-2"
                      />
                      <span className="text-subColor-1">{item}</span>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div id="partner-form" ref={partnerFormRef} >
      <PartnerForm />
      </div>
    </>
  );
};
export default Partners;
