import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTelegram,
  faDiscord,
  faXTwitter,
  faYoutube,
  faMedium,
  faLinkedinIn,
} from "@fortawesome/free-brands-svg-icons";
import {
  GoogleStoreImage,
  AppleStoreImage,
  LydiaLogo,
} from "../../constants/assets";

const Footer: React.FC = () => {
  return (
    <footer className="font-[Montserrat] w-full bg-[#0f2b43] text-white py-[40px] md:py-[80px] mt-auto">
      <div className="b-container-big mx-auto px-[16px] grid grid-cols-3 md:grid-cols-7 gap-[32px]">
        {/* Logo and Social Media Icons */}
        <div className="flex flex-col space-y-[16px] col-span-3 md:col-span-3">
          <div className="flex items-center justify-between md:justify-start space-x-[12px]">
            <div className="flex md:flex-1 flex-row items-center space-x-[8px]">
              <img src={LydiaLogo} alt="Logo" className="w-[160px] h-[40px]" />
            </div>
            <Link
              to="mailto:contact@lydiacoins.com"
              className="text-[16px] font-semibold md:flex-1 hover:underline text-white ml-[16px]"
            >
              contact@lydiacoins.com
            </Link>
          </div>
          <div className="flex space-x-[12px]">
            <Link to="https://t.me/LydiaCoinsOfficial" target="_blank" className="cursor-pointer">
              <FontAwesomeIcon
                icon={faTelegram}
                className="w-[20px] h-[20px] FooterIcon hover:bg-[#24A1DE]"
              />
            </Link>
            <Link to="https://discord.gg/LydiaCoinsCommunity" target="_blank" className="cursor-pointer">
              <FontAwesomeIcon
                icon={faDiscord}
                className="w-[20px] h-[20px] FooterIcon hover:bg-[#5865F2]"
              />
            </Link>
            <Link to="https://x.com/LydiaCoins" target="_blank" className="cursor-pointer">
              <FontAwesomeIcon
                icon={faXTwitter}
                className="w-[20px] h-[20px] FooterIcon hover:bg-[#000]"
              />
            </Link>
            <Link to="https://www.youtube.com/@LydiaCoins" target="_blank" className="cursor-pointer">
              <FontAwesomeIcon
                icon={faYoutube}
                className="w-[20px] h-[20px] FooterIcon hover:bg-[#FF0000]"
              />
            </Link>
            {/* <Link to="/">
              <FontAwesomeIcon
                icon={faMedium}
                className="w-[20px] h-[20px] FooterIcon hover:bg-[#000]"
              />
            </Link> */}
            <Link to="https://www.linkedin.com/company/lydiacoins" target="_blank" className="cursor-pointer">
              <FontAwesomeIcon
                icon={faLinkedinIn}
                className="w-[20px] h-[20px] FooterIcon hover:bg-[#0072b1]"
              />
            </Link>
          </div>
          <p className="text-[14px] text-gray-400">
            © Lydia Coins
          </p>

        </div>

        {/* Main Links */}
        <div className="flex flex-col !space-y-[8px]">
          {/* <h3 className="text-gray-400 text-[16px] leading-[24px]">Main</h3>
          <Link
            to="/"
            className="text-[16px] font-semibold hover:underline text-white"
          >
            Home
          </Link>
          */}
        </div>

        {/* LYD Links */}
        <div className="flex flex-col !space-y-[8px]">
          <h3 className="text-gray-400 text-[16px] leading-[24px]">LYD</h3>
          <Link
            to="/about-lyd"
            className="text-[16px] font-semibold hover:underline text-white"
          >
            About
          </Link>
          <Link
            to="/use-cases"
            className="text-[16px] font-semibold hover:underline text-white"
          >
            Use Cases
          </Link>
          <Link
            to="/transparency"
            className="text-[16px] font-semibold hover:underline text-white"
          >
            Transparency
          </Link>
        </div>

        {/* Company Links */}
        <div className="flex flex-col !space-y-[8px]">
          <h3 className="text-gray-400 text-[16px] leading-[24px]">Company</h3>

          <Link
            to="/faq"
            className="text-[16px] font-semibold hover:underline text-white"
          >
            FAQ
          </Link>
          <Link
            to="/blog"
            className="text-[16px] font-semibold hover:underline text-white"
          >
            Blog
          </Link>
        </div>

        {/* App Download Buttons */}
        <div className="flex flex-col col-span-2 md:col-span-1 !space-y-[8px]">
          <h3 className="text-gray-400 text-[16px] leading-[24px]">
            Lydia Wallet
          </h3>
          <div className="flex flex-row md:flex-col space-x-[8px] md:space-x-0 md:space-y-[8px]">
            <Link to="/" className="hover:opacity-75">
              <img
                src={AppleStoreImage}
                alt="App Store"
                className="w-[128px] h-auto"
              />
            </Link>
            <Link to="/" className="hover:opacity-75">
              <img
                src={GoogleStoreImage}
                alt="Google Play"
                className="w-[128px] h-auto"
              />
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
