import {
  AboutBenefit,
  AboutDefine1,
  AboutDefine2,
  AboutDefine3,
  AboutDefine4,
} from "../../constants/assets";

import JoinLydia from "../../constants/JoinLydia";
import HelmetComponent from "../../component/Reusable/HelmetComponent";
import AboutList from "../About/AboutList";
import AboutDesc from "../About/AboutDesc";

import "./Wallet.css";

const Wallet = () => {

  const workData = [
    {
      title: `Acquire Lydia Coin`,
      desc: `To start using Lydia Coin, users can purchase 
      the token through the Lydia Wallet or on supported 
      exchanges. By linking a bank account or using other payment
       methods, users can easily exchange fiat currency 
       (like USD, Euro, or Turkish Lira) for Lydia Coin. 
       Each token is fully backed by real-world assets, 
       ensuring the value of your investment is stable and secure.`
    },
    {
      title: `Store Safely`,
      desc: `Once acquired, Lydia Coins can be stored securely in any compatible digital wallet. The Lydia Wallet offers an intuitive platform for managing and storing your coins, but you can also use other blockchain-supported wallets. Lydia Coin’s blockchain architecture ensures that all transactions are protected by advanced cryptographic security, giving you peace of mind that your assets are safe from hacking or fraud.`
    },
    {
      title: `Make Transactions`,
      desc: `Lydia Coin can be used for a variety of transactions, including everyday payments, online purchases, remittances, or even larger financial transfers. The blockchain network processes transactions almost instantly, allowing for quick payments with minimal fees. Users can send Lydia Coins to anyone in the world, making it an ideal solution for cross-border payments without the delays and costs associated with traditional banking systems.`
    },
    {
      title: `Enjoy Low Transaction Costs`,
      desc: `One of the significant benefits of using Lydia Coin is the low transaction fees. Unlike traditional financial institutions, which often charge high fees for international transfers and currency exchanges, Lydia Coin’s blockchain-based system ensures that transaction costs are kept to a minimum, making it a cost-effective option for individuals and businesses alike.`
    },
    {
      title: ` Monitor Transactions and Reserves`,
      desc: `Lydia Coin emphasizes transparency. With the use of Proof of Reserves, users can verify that each Lydia Coin in circulation is backed 1:1 by real-world assets. The blockchain provides an auditable trail of all transactions, ensuring users have complete visibility into how the system operates. Regular third-party audits further enhance trust by confirming that every Lydia Coin is fully backed by fiat currencies or other assets.`
    },
    {
      title: `Participate in the Lydia Ecosystem`,
      desc: `Lydia Coin is more than just a digital currency. It’s part of a broader ecosystem that includes staking, governance participation, and yield farming. Users can stake their Lydia Coins to earn rewards or participate in the decentralized governance of the platform, helping to shape the future direction of Lydia Coin through a community-driven decision-making process.`
    },
  ]

  const benefitData = [
    {
      title: 'Stability through Asset-Backing',
      desc: 'Each Lydia Coin is backed by real-world assets like fiat currencies, ensuring price stability and making it a reliable option for users.',
    },
    {
      title: 'Low Transaction Fees',
      desc: 'Enjoy minimal fees for both small and large transactions, making Lydia Coin an affordable choice for everyday payments and business transfers.',
    },
    {
      title: 'Global Accessibility',
      desc: 'Lydia Coin offers borderless transactions, allowing users to send and receive money across the world quickly and easily without the need for traditional banking.',
    },
    {
      title: 'Enhanced Security',
      desc: 'Built on the Ethereum blockchain, Lydia Coin utilizes smart contracts and advanced encryption to ensure that every transaction is secure and verifiable.',
    },
    {
      title: 'Transparency & Trust',
      desc: 'With regular audits and a 1:1 reserve system, Lydia Coin provides users with full transparency, ensuring that each coin is always fully backed by its corresponding asset.',
    },
    {
      title: 'Fast & Efficient Transactions',
      desc: 'Unlike traditional banking systems, Lydia Coin processes transactions almost instantly, reducing wait times and improving overall efficiency for both personal and business uses.',
    },
  ]
  return (
    <>
      <HelmetComponent
        title="Lydia Coin - Wallet"
        description="Learn more about Lydia Coin, its mission, and its team."
        keywords="Lydia Coin, Wallet, Mission, Team"
        ogTitle="Lydia Coin - Wallet"
        ogDescription="Learn more about Lydia Coin, its mission, and its team."
        ogImage="https://lydia-coin-landing-page.vercel.app"
        ogUrl="https://lydia-coin-landing-page.vercel.app"
        twitterTitle="Lydia Coin - Wallet"
        twitterDescription="Learn more about Lydia Coin, its mission, and its team."
        twitterImage="https://lydia-coin-landing-page.vercel.app"
      />
      <section className="b-container-big w-full flex justify-center items-center pt-28 lg:pt-28 pb-20">
        <div className="flex flex-col max-w-[1100px] items-center gap-[10px]">
          <div className="text-center lydiaTitleContainer">
            Lydia Wallet
          </div>
          <div className="text-subColor max-w-[986px] text-center">
            The Lydia Wallet is your comprehensive solution for managing, storing, and transacting with Lydia Coin (LYD) and related tokens. Our feature-rich platform caters to both novice users and experienced crypto enthusiasts, offering a perfect blend of security, functionality, and ease of use.
          </div>
        </div>
      </section>
      <section className="b-container-big w-full flex justify-center items-center pb-20">
        <div className="flex flex-col max-w-[1100px] items-center gap-[10px]">
          <div className="text-center lydiaTitleContainer">
            Manage Your Lydia Coin with Ease
          </div>
          <div className="text-subColor max-w-[986px] text-center">
            Our Lydia Wallet offers a user-friendly interface for seamless transactions, allowing users to send, receive, and manage their coins effortlessly. The wallet also includes additional features like multi-sig security.
          </div>
        </div>
      </section>
      <section className="b-container-big w-full flex justify-center items-center pb-20">
        <div className="bg-white p-6 rounded-lg shadow-lg text-center">
          <h2 className="text-2xl font-bold mb-4">Download the Lydia Mobile App</h2>
          <p className="text-gray-700 mb-6">Get the app for your device:</p>
          <div className="flex justify-center gap-4">
            <a href="https://www.apple.com/app-store/" target="_blank"
              className="bg-black text-white px-6 py-3 rounded-lg flex items-center gap-2 shadow-md hover:bg-gray-800 transition">
              <img src="https://upload.wikimedia.org/wikipedia/commons/3/31/Apple_logo_white.svg" alt="Apple" className="w-6 h-6" />
              Download on the App Store
            </a>
            <a href="https://play.google.com/store" target="_blank"
              className="bg-green-500 text-white px-6 py-3 rounded-lg flex items-center gap-2 shadow-md hover:bg-green-600 transition">
              <img src="https://upload.wikimedia.org/wikipedia/commons/d/d0/Google_Play_Arrow_logo.svg" alt="Google Play" className="w-6 h-6" />
              Get it on Google Play
            </a>
          </div>
        </div>
      </section>
      {/* <section className="b-container-big w-full flex justify-center items-center pb-20">
        <div className="flex flex-col max-w-[1100px] items-center gap-[10px]">
        
        </div>
      </section> */}
      {/* <AboutDesc title='Shape the Future of Lydia Coin'
        desc={`With Lydia Coin, holders can actively participate in governance decisions, helping to shape the future direction of the ecosystem. Have your voice heard and vote on key issues, from development milestones to community-driven initiatives.`}
      />
       <AboutDesc title='Staking & Yield Farming'
        desc={`Stake your Lydia Coins to earn rewards and grow your holdings. With flexible staking options and attractive yield farming opportunities, Lydia Coin provides a great way to increase your wealth.`}
      />
      <AboutDesc title='Cross-chain Functionality'
        desc={`Lydia Coin offers cross-chain functionality, allowing users to seamlessly transfer tokens across various blockchains. This feature ensures that you can utilize Lydia Coin on different platforms without any hassle.`}
      /> */}
      <JoinLydia contactOnly={true} />
    </>
  );
};
export default Wallet;
