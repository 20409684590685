import React from "react";
import {
  LydiaCoin2,
  UseCase1,
  UseCase2,
  UseCase3,
  UseCase4,
} from "../../constants/assets";
import JoinLydia from "../../constants/JoinLydia";
import HelmetComponent from "../../component/Reusable/HelmetComponent";
const UseCases: React.FC = () => {
  const Slide = [
    {
      title: "Effortless Cross-Border Transactions",
      description:
        "Lydia Coin (LYD) enables users to engage in seamless international transactions, transforming the way money is sent across borders. This innovative solution reduces reliance on traditional banking systems that are often costly and slow.",
      listPoint: [
        {
          title: "Quick and Affordable Payments:",
          desc: "Facilitate quick and affordable cross-border payments with Lydia Coin. Unlike traditional banking systems, which often involve high fees and lengthy processing times, our stable coin allows for seamless international transactions."
        },
        {
          title: "Global Accessibility:",
          desc: "Businesses and individuals can send money abroad frequently, reducing costs and eliminating delays. This global reach enhances economic opportunities, especially for freelancers and small businesses."
        }
      ],
      imgSrc: UseCase1,
      reverse: false,
      width: 350,
      height: 350,
      bgWhite: true,
    },
    {
      title: "Hedging Against Volatility",
      description:
        "In a world where cryptocurrency values can fluctuate dramatically, Lydia Coin (LYD) serves as a safeguard for your assets. By providing a stable alternative, it allows users to protect their investments effectively.",
      listPoint: [
        {
          title: "Asset Protection:",
          desc: "Protect your wealth during periods of market volatility by converting other cryptocurrencies into Lydia Coin. This allows you to hedge against sharp price swings and maintain the value of your assets."
        },
        {
          title: "Market Stability:",
          desc: "In uncertain market conditions, having a stable asset like Lydia Coin provides peace of mind and financial security, allowing users to navigate market fluctuations confidently."
        }
      ],
      imgSrc: UseCase2,
      reverse: true,
      bgWhite: false,
    },
    {
      title: "Protect and Grow Your Wealth",
      description:
        "Lydia Coin (LYD) offers a reliable solution for those seeking a stable store of value. It stands out as a safe alternative for saving and investing in a rapidly changing economic environment.",
      listPoint: [
        {
          title: "Safe Haven for Savings:",
          desc: "In an era of economic uncertainty, Lydia Coin offers a safe haven for your savings and investments. Its stable value shields your assets from the volatility of the cryptocurrency market and inflation."
        },
        {
          title: "Flexible Conversion:",
          desc: "Easily convert Lydia Coin back into fiat currency whenever you need it, giving you full control over your financial future and making it a practical alternative to traditional savings accounts."
        }
      ],
      imgSrc: UseCase3,
      reverse: false,
      bgWhite: true,
    },
    {
      title: "Send Money Home Instantly",
      description:
        "Sending remittances can often be fraught with high costs and long wait times. Lydia Coin streamlines this process, ensuring funds reach their destination quickly and affordably.",
      listPoint: [
        {
          title: "Simplified Remittances:",
          desc: "Lydia Coin simplifies the process of sending remittances to family and friends in other countries. Traditional remittance services can be expensive and slow, but Lydia offers a fast, transparent, and low-cost solution."
        },
        {
          title: "Instant Transfers:",
          desc: "Your loved ones can receive their funds instantly, with no hidden fees, making Lydia Coin the ideal choice for remittances and enhancing support for families abroad."
        }
      ],
      imgSrc: UseCase4,
      reverse: true,
      bgWhite: false,
    },

    {
      title: "E-Commerce Integration",
      description:
        "The rise of digital shopping necessitates efficient payment solutions. Lydia Coin (LYD) provides businesses with an effective way to integrate stable digital payments into their platforms.",
      listPoint: [
        {
          title: "Streamlined Payments:",
          desc: "Lydia Coin allows for seamless integration into e-commerce platforms, enabling businesses to accept payments in a stable digital currency. This fosters a smooth shopping experience for customers."
        },
        {
          title: "Reduced Transaction Fees:",
          desc: "By using Lydia Coin, e-commerce businesses can significantly reduce transaction fees compared to traditional credit card payments, maximizing profit margins while offering competitive pricing to customers."
        }
      ],
      imgSrc: UseCase3,
      reverse: false,
      bgWhite: true,
    },
    {
      title: "Digital Payments",
      description:
        "As more consumers turn to digital transactions, Lydia Chain ensures secure and efficient payment options. This stability makes it an attractive choice for both merchants and consumers.",
      listPoint: [
        {
          title: "Enhanced Security:",
          desc: "Utilizing Lydia Coin (LYD) for digital payments provides enhanced security compared to conventional payment methods. Transactions are encrypted and secure, minimizing the risk of fraud."
        },
        {
          title: "Instant Settlements:",
          desc: "With Lydia Coin, merchants benefit from instant settlements, allowing for improved cash flow and operational efficiency, making it an attractive option for businesses of all sizes."
        }
      ],
      imgSrc: UseCase4,
      reverse: true,
      bgWhite: false,
    },

  ];

  return (
    <>
      <HelmetComponent
        title="Lydia Coin - Use Cases"
        description="Explore the various use cases of Lydia Coin in the digital economy."
        keywords="Lydia Coin, Use Cases, Digital Economy, Cryptocurrency"
        ogTitle="Lydia Coin - Use Cases"
        ogDescription="Explore the various use cases of Lydia Coin in the digital economy."
        ogImage="https://lydia-coin-landing-page.vercel.app"
        ogUrl="https://lydia-coin-landing-page.vercel.app"
        twitterTitle="Lydia Coin - Use Cases"
        twitterDescription="Explore the various use cases of Lydia Coin in the digital economy."
        twitterImage="https://lydia-coin-landing-page.vercel.app"
      />
      {/*New Usecase page*/}
      <div className="b-container-big pt-28 py-8">
        <div className="flex flex-col-reverse md:flex-row justify-between items-center  ">
          <div className="md:w-1/2">
            <h2 className="lydiaTitleContainer flex flex-col mb-4">
              Empowering the Future of Finance
            </h2>
            <p className="text-subColor mb-0 opacity-6 pt-6 ">
              Lydia Coin (LYD) is more than just a stablecoin; it’s a versatile digital asset designed to meet the needs of today’s dynamic financial landscape. With its stable value, security, and ease of use, Lydia Coin opens up a world of possibilities for individuals and businesses alike.
            </p>
          </div>
          <div className="md:w-1/3 mt-8 md:mt-0 flex justify-center">
            <img
              src={LydiaCoin2}
              alt="LYD Stablecoin Logo"
              className="scene"
              width={400}
              height={400}
            />
          </div>
        </div>
      </div>
      <div className="block m-auto mt-[100px]">
        <section className="b-container-big md:flex flex-col items-center">
          <div className="relative w-full">
            <div className="flex flex-col gap-[90px] md:gap-[120px] md:pb-[54px] pb-[64px]">
              {Slide.map((slide, index) => (
                <div
                  key={index}
                  className={`b-container-big ${slide.bgWhite ? "bg-white" : "bg-#fafafb"
                    } md:!p-8 rounded-xl w-full md:flex ${slide.reverse ? "md:flex-row-reverse" : "md:flex-row "
                    } items-center flex-col justify-between`}
                >
                  <div className="landingHeaderImg m-auto md:m-0 mb-10 md:mb-0">
                    <img
                      src={slide.imgSrc}
                      alt={slide.title}
                      className="scene m-auto"
                      width={slide.width ? slide.width : 400}
                      height={slide.height ? slide.height : 400}
                    />
                  </div>
                  <div className="md:w-1/2 flex flex-col justify-center">
                    <h1 className="lydiaSubtitleContainer mb-2">
                      {slide.title}
                    </h1>
                    <h4 className="text-subColor max-w-full">
                      {slide.description}
                    </h4>
                    {slide?.listPoint?.map((item, idx) => (<ul key={idx} className="list-disc list-inside text-gray-700 mt-2">
                      <li><span className="font-bold">{item?.title}</span><span className="ml-1 leading-6 text-subColor">{item?.desc}</span></li>
                    </ul>))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      </div>
      <JoinLydia />
    </>
  );
};

export default UseCases;
