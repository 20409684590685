import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { Layout } from "./Layout";
import RouteComponent from "../../route/routes";
import { ToastContainer } from "react-toastify";

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    if ("scrollRestoration" in window.history) {
      window.history.scrollRestoration = "manual";
    }
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const MainLayout = (): JSX.Element => {
  const { pathname } = useLocation();

  const withoutLayout = ["/activity",'/acceptance'];
  return (
    <>
      {withoutLayout.includes(pathname) ? (
        <>
          <ToastContainer />
          <ScrollToTop />
          <RouteComponent />
        </>
      ) : (
        <Layout>
          <>
            <ToastContainer />
            <ScrollToTop />
            <RouteComponent />
          </>
        </Layout>
      )}
    </>
  );
};

export default MainLayout;
