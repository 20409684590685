import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage, FormikHelpers } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

interface FormValues {
  firstName: string;
  lastName: string;
  workEmail: string;
  jobTitle: string;
  companyName: string;
  country: string;
  websiteURL: string;
  partnerProgramInterest: string;
  whyPartner: string;
}

interface Country {
  name: string;
}

const PartnerForm: React.FC = () => {
  const initialValues: FormValues = {
    firstName: "",
    lastName: "",
    workEmail: "",
    jobTitle: "",
    companyName: "",
    country: "",
    websiteURL: "",
    partnerProgramInterest: "",
    whyPartner: "",
  };

  const [countries, setCountries] = useState<Country[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        if (!process.env.REACT_APP_BACKEND_URL) {
          throw new Error("REACT_APP_BACKEND_URL is not defined");
        }
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/payment/supported-currencies`
        );
        setCountries(response?.data?.data);
      } catch (err: any) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchCountries();
  }, []);

  const validationSchema = Yup.object({
    firstName: Yup.string()
      .matches(/^(?!\s)[A-Za-z ]+(?<!\s)$/, "Please enter valid First name")
      .required("First Name is required"),
    lastName: Yup.string()
      .matches(/^(?!\s)[A-Za-z ]+(?<!\s)$/, "Please enter valid First name")
      .required("Last Name is required"),
    workEmail: Yup.string()
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        "Invalid email"
      )
      .required("Work Email is required"),
    jobTitle: Yup.string()
      .matches(/^(?!\s)[A-Za-z ]+(?<!\s)$/, "Please enter valid Job Title")
      .required("Job Title is required"),
    companyName: Yup.string()
      .matches(/^(?!\s)[A-Za-z ]+(?<!\s)$/, "Please enter valid Compamy name")
      .required("Company Name is required"),
    country: Yup.string().required("Country is required"),
    websiteURL: Yup.string()
      .url("Invalid URL")
      .required("Website URL is required"),
    partnerProgramInterest: Yup.string().required("This field is required"),
    whyPartner: Yup.string().required("This field is required"),
  });

  const onSubmit = async (
    values: FormValues,
    { resetForm }: FormikHelpers<FormValues>
  ) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/marketing/`,
        {
          type: "partner",
          fname: values.firstName.trim(),
          lname: values.lastName.trim(),
          email: values.workEmail,
          job: values.jobTitle.trim(),
          company: values.companyName.trim(),
          country: values.country,
          website: values.websiteURL,
          interest: values.partnerProgramInterest,
          description: values.whyPartner.trim(),
        }
      );

      toast.success(response.data?.msg || "Form submitted successfully.");

      resetForm();
    } catch (error: unknown) {
      if (axios.isAxiosError(error) && error?.response?.data?.msg) {
        toast.error(error?.response?.data?.msg);
      } else if (error instanceof Error) {
        toast.error(error.message);
      } else {
        toast.error("An unknown error occurred.");
      }
    }
  };

  return (
    <div className="b-container-big py-16 ">
      <div className="flex md:px-4 flex-1 justify-center items-center flex-col min-w-64">
        <h2 className="lydiaSubtitleContainer font-bold mb-2">
          Become a Partner
        </h2>
        <p className="mb-6 text-subColor">
          Explore how you can partner with Lydia. Apply today.
        </p>
      </div>
      <div className="flex justify-center mt-8 items-start flex-wrap">
        <div className="md:p-8 flex-1 !pt-0 rounded-md w-full max-w-2xl min-w-64">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({ isSubmitting }) => (
              <Form className="space-y-4">
                {error && (
                  <div className="warning-color text-sm mt-1">{error}</div>
                )}
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div>
                    <label htmlFor="firstName" className="block text-gray-700">
                      First Name
                      <span className="warning-color">*</span>
                    </label>
                    <Field
                      name="firstName"
                      type="text"
                      className="w-full px-4 py-1 bg-slate-200 mt-1 border border-gray-400 rounded-lg"
                    />
                    <ErrorMessage
                      name="firstName"
                      component="div"
                      className="warning-color text-sm mt-1"
                    />
                  </div>

                  <div>
                    <label htmlFor="lastName" className="block text-gray-700">
                      Last Name
                      <span className="warning-color">*</span>
                    </label>
                    <Field
                      name="lastName"
                      type="text"
                      className="w-full px-4 py-1 bg-slate-200 mt-1 border border-gray-400 rounded-lg"
                    />
                    <ErrorMessage
                      name="lastName"
                      component="div"
                      className="warning-color text-sm mt-1"
                    />
                  </div>
                </div>

                <div>
                  <label htmlFor="workEmail" className="block text-gray-700">
                    Work Email
                    <span className="warning-color">*</span>
                  </label>
                  <Field
                    name="workEmail"
                    type="email"
                    className="w-full px-4 py-1 bg-slate-200 mt-1 border border-gray-400 rounded-lg"
                  />
                  <ErrorMessage
                    name="workEmail"
                    component="div"
                    className="warning-color text-sm mt-1"
                  />
                </div>

                <div>
                  <label htmlFor="jobTitle" className="block text-gray-700">
                    Job Title
                    <span className="warning-color">*</span>
                  </label>
                  <Field
                    name="jobTitle"
                    type="text"
                    className="w-full px-4 py-1 bg-slate-200 mt-1 border border-gray-400 rounded-lg"
                  />
                  <ErrorMessage
                    name="jobTitle"
                    component="div"
                    className="warning-color text-sm mt-1"
                  />
                </div>

                <div>
                  <label htmlFor="companyName" className="block text-gray-700">
                    Company Name
                    <span className="warning-color">*</span>
                  </label>
                  <Field
                    name="companyName"
                    type="text"
                    className="w-full px-4 py-1 bg-slate-200 mt-1 border border-gray-400 rounded-lg"
                  />
                  <ErrorMessage
                    name="companyName"
                    component="div"
                    className="warning-color text-sm mt-1"
                  />
                </div>

                <div>
                  <label htmlFor="country" className="block text-gray-700">
                    Country
                    <span className="warning-color">*</span>
                  </label>
                  <Field
                    name="country"
                    as="select"
                    disabled={loading}
                    className="w-full px-4 py-1 bg-slate-200 mt-1 border border-gray-400 rounded-lg"
                  >
                    {loading ? (
                      <option>Loading Countries...</option>
                    ) : (
                      <>
                        <option value="">Please Select</option>
                        {countries.map((item) => {
                          return (
                            <option key={item.name} value={item.name}>
                              {item.name}
                            </option>
                          );
                        })}
                      </>
                    )}
                    {/* Add more options as needed */}
                  </Field>
                  <ErrorMessage
                    name="country"
                    component="div"
                    className="warning-color text-sm mt-1"
                  />
                </div>

                <div>
                  <label htmlFor="websiteURL" className="block text-gray-700">
                    Website URL
                    <span className="warning-color">*</span>
                  </label>
                  <Field
                    name="websiteURL"
                    type="text"
                    className="w-full px-4 py-1 bg-slate-200 mt-1 border border-gray-400 rounded-lg"
                  />
                  <ErrorMessage
                    name="websiteURL"
                    component="div"
                    className="warning-color text-sm mt-1"
                  />
                </div>

                <div>
                  <label
                    htmlFor="partnerProgramInterest"
                    className="block text-gray-700"
                  >
                    Partner Program Interest
                    <span className="warning-color">*</span>
                  </label>
                  <Field
                    name="partnerProgramInterest"
                    as="select"
                    className="w-full px-4 py-1 bg-slate-200 mt-1 border border-gray-400 rounded-lg"
                  >
                    <option value="">Please Select</option>
                    <option value="Reseller">Reseller</option>
                    <option value="Technology Partner">
                      Technology Partner
                    </option>
                    <option value="Service Partner">Service Partner</option>
                    {/* Add more options as needed */}
                  </Field>
                  <ErrorMessage
                    name="partnerProgramInterest"
                    component="div"
                    className="warning-color text-sm mt-1"
                  />
                </div>

                <div>
                  <label htmlFor="whyPartner" className="block text-gray-700">
                    Why Do You Want To Partner With Lydia?
                    <span className="warning-color">*</span>
                  </label>
                  <Field
                    name="whyPartner"
                    as="textarea"
                    rows={4}
                    className="w-full px-4 py-1 bg-slate-200 mt-1 border border-gray-400 rounded-lg"
                  />
                  <ErrorMessage
                    name="whyPartner"
                    component="div"
                    className="warning-color text-sm mt-1"
                  />
                </div>

                <div className="text-left">
                  <button
                    type="submit"
                    className="btnPrimary m-auto md:m-0 w-max btnDefault max-h-[48px] flex flex-row items-center justify-center md:mb-0 md:mr-2"
                    // className="px-4 py-2 bg-[#7D011C] text-white rounded-md hover:bg-[#2568e4] focus:outline-none focus:ring focus:ring-blue-300"
                    disabled={isSubmitting}
                  >
                    Submit
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default PartnerForm;
