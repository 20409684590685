import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";

const Approval = () => {
  const [loading, setloading] = useState(true);
  const [status, setStatus] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const code: any = searchParams.get("code");

  const checkCode = async (code: string) => {
    setloading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/account/validate/code`,
        {
          code: code,
          type: "acceptance",
        }
      );
      setloading(false);

      // toast.success(response.data.msg);
    } catch (error: unknown) {
      setloading(true);

      if (axios.isAxiosError(error) && error?.response?.data?.msg) {
        toast.error(error?.response?.data?.msg);
      } else if (error instanceof Error) {
        toast.error(error.message);
      } else {
        toast.error("An unknown error occurred.");
      }
      navigate("/");
    }
  };

  const checkConfirmation = async (val: string, status: string) => {
    setloading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/account/validate/nominee-acceptance`,
        {
          code: val,
          status: status,
        }
      );
      console.log("res", response);
      setloading(false);

      // toast.success(response.data.msg);
    } catch (error: unknown) {
      setloading(true);

      if (axios.isAxiosError(error) && error?.response?.data?.msg) {
        toast.error(error?.response?.data?.msg);
      } else if (error instanceof Error) {
        toast.error(error.message);
      } else {
        toast.error("An unknown error occurred.");
      }
      // navigate("/");
    }
  };

  const handleStatus = (val: string) => {
    checkConfirmation(code, val);
  };

  useEffect(() => {
    if (code) {
      checkCode(code);
    }
  }, [code]);
  return (
    <div className="flex items-center justify-center min-h-screen">
       {loading ? (
        <div role="status">
          <svg
            aria-hidden="true"
            className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
            viewBox="0 0 100 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="currentColor"
            />
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="currentFill"
            />
          </svg>
          <span className="sr-only">Loading...</span>
        </div>
      ) : (
      <div className="max-w-md mx-auto bg-white p-8 shadow-md rounded-lg text-center">
        <h1 className="text-3xl font-bold text-gray-800 mb-4">
          Inheritance Approval
        </h1>
        <p className="text-gray-600 mb-6">
          You have been selected to receive access to the wallet associated with
          Lydia Wallet by your nominator. Please Accept or Reject.
        </p>

        <div className="space-y-4">
          <div className="border rounded-lg p-4">
            <button
              className="w-full bg-white text-gray-800 font-semibold border border-gray-400 rounded py-2 mb-2 hover:bg-gray-100"
              onClick={() => handleStatus("accepted")}
            >
              Accept Inheritance
            </button>
            <p className="text-gray-600 text-sm">
              By accepting, you agree to follow the additional steps required to
              verify your identity. This includes a KYC verification process to
              confirm nominee identity. Once verification is complete, you will
              receive the access to the wallet.
            </p>
          </div>

          <div className="border rounded-lg p-4">
            <button
              className="w-full bg-white text-gray-800 font-semibold border border-gray-400 rounded py-2 mb-2 hover:bg-gray-100"
              onClick={() => handleStatus("declined")}
            >
              Reject Inheritance
            </button>
            <p className="text-gray-600 text-sm">
              By declining, you opt out of accepting the wallet. It will be
              passed on to the next nominee in line. If you have any questions
              or need assistance, please reach out to{" "}
              <a
                href="mailto:contact@lydiacoins.com"
                className="text-blue-500 underline"
              >
                contact@lydiacoins.com
              </a>
            </p>
          </div>
        </div>
      </div>
      )}
    </div>
  );
};
export default Approval;
