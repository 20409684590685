
const ModalTable = ({ open, onClose, title='File',tableData=[] }: { open: boolean; title?: string, onClose: () => void ,tableData?:any}) => {
  if (!open) return null;

  const handleDownload = (fileName: string) => {
    window.open(fileName)
    // alert(`Downloading ${fileName}`);
    // Add your download logic here
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white rounded-lg w-11/12 md:w-3/4 lg:w-1/2 p-6">
        <h2 className="text-xl font-semibold mb-4">{title}</h2>
        <table className="min-w-full border border-gray-300">
          <thead>
            <tr className="bg-gray-100">
              <th className="px-4 py-2 text-left border-b">Sl. No</th>
              <th className="px-4 py-2 text-left border-b">File Name</th>
              <th className="px-4 py-2 text-left border-b">Update Date</th>
              <th className="px-4 py-2 text-left border-b">Open</th>
            </tr>
          </thead>
          <tbody>
            {tableData?.map((row:any,idx:number) => (
              <tr key={row?.id} className="hover:bg-gray-50">
                <td className="px-4 py-2 border-b">{idx + 1}</td>
                <td className="px-4 py-2 border-b">{row?.Key?.split('/')[1]}</td>
                <td className="px-4 py-2 border-b">{new Date(row?.LastModified).toLocaleDateString()}</td>
                <td className="px-4 py-2 border-b">
                  <button
                    onClick={() => handleDownload(row?.uri)}
                    className="bg-blue-500 hover:bg-blue-600 text-white py-1 px-3 rounded"
                  >
                    Open
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="flex justify-end mt-4">
          <button
            onClick={onClose}
            className="bg-gray-500 hover:bg-gray-600 text-white py-1 px-4 rounded"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default ModalTable