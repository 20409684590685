import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "../pages/Home/Home";
import { Blog } from "../pages/Blog/Blog";
import { NotFound } from "../pages/Not Found/notFound";
import { Faq } from "../pages/faq/Faq";
import About from "../pages/About/About";
import Usecases from "../pages/Usecases/Usecases";
import { Transparency } from "../pages/transparency/Transparency";
import Partners from "../pages/Partners/Partners";
import ContactUs from "../pages/ContactUs/ContactUs";
import TermsAndConditions from "../pages/TermsAndConditions/Terms";
import { BlogDetail } from "../pages/Blog/BlogDetail";
import Confirmation from "../pages/Confirmation/Confirmation";
import Approval from "../pages/Approval/Approval";
import Wallet from "../pages/Wallet/Wallet";

const RouteComponent = () => (
  <Routes>
    <Route path="/" element={<Home />} />
    <Route path="/activity" element={<Confirmation />} />
    <Route path="/acceptance" element={<Approval />} />
    <Route path="/blog" element={<Blog />} />
    <Route path="/blog/:id" element={<BlogDetail />} />
    <Route path="/about-lyd" element={<About />} />
    <Route path="/wallet" element={<Wallet />} />
    <Route path="/use-cases" element={<Usecases />} />
    <Route path="/faq" element={<Faq />} />
    <Route path="/transparency" element={<Transparency />} />
    <Route path="/partners" element={<Partners />} />
    <Route path="/contact-us" element={<ContactUs />} />
    <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
    <Route path="*" element={<NotFound />} />
  </Routes>
);

export default RouteComponent;
