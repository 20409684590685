
  const AboutDesc = ({title='',desc='',wrapperClass='pb-20'}) => {
    return (
      <>
        <section className={`b-container-big w-full flex justify-center items-center ${wrapperClass}`}>
          <div className="flex flex-col max-w-[1100px] items-center gap-[10px]">
            <div className="text-center lydiaTitleContainer">
            {title}
            </div>
            <div className="text-subColor max-w-[986px] text-center">
            {desc}
            </div>
          </div>
        </section>
      </>
    );
  };
  export default AboutDesc;
  