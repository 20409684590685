import {
  AboutBenefit,
  AboutDefine1,
  AboutDefine2,
  AboutDefine3,
  AboutDefine4,
} from "../../constants/assets";

import JoinLydia from "../../constants/JoinLydia";
import HelmetComponent from "../../component/Reusable/HelmetComponent";

import "./About.css";
import AboutDesc from "./AboutDesc";
import AboutList from "./AboutList";

const About = () => {

  const workData = [
    {
      title: `Acquire Lydia Coin`,
      desc: `To start using Lydia Coin, users can purchase 
      the token through the Lydia Wallet or on supported 
      exchanges. By linking a bank account or using other payment
       methods, users can easily exchange fiat currency 
       (like USD, Euro, or Turkish Lira) for Lydia Coin. 
       Each token is fully backed by real-world assets, 
       ensuring the value of your investment is stable and secure.`
    },
    {
      title: `Store Safely`,
      desc: `Once acquired, Lydia Coins can be stored securely in any compatible digital wallet. The Lydia Wallet offers an intuitive platform for managing and storing your coins, but you can also use other blockchain-supported wallets. Lydia Coin’s blockchain architecture ensures that all transactions are protected by advanced cryptographic security, giving you peace of mind that your assets are safe from hacking or fraud.`
    },
    {
      title: `Make Transactions`,
      desc: `Lydia Coin can be used for a variety of transactions, including everyday payments, online purchases, remittances, or even larger financial transfers. The blockchain network processes transactions almost instantly, allowing for quick payments with minimal fees. Users can send Lydia Coins to anyone in the world, making it an ideal solution for cross-border payments without the delays and costs associated with traditional banking systems.`
    },
    {
      title: `Enjoy Low Transaction Costs`,
      desc: `One of the significant benefits of using Lydia Coin is the low transaction fees. Unlike traditional financial institutions, which often charge high fees for international transfers and currency exchanges, Lydia Coin’s blockchain-based system ensures that transaction costs are kept to a minimum, making it a cost-effective option for individuals and businesses alike.`
    },
    {
      title: ` Monitor Transactions and Reserves`,
      desc: `Lydia Coin emphasizes transparency. With the use of Proof of Reserves, users can verify that each Lydia Coin in circulation is backed 1:1 by real-world assets. The blockchain provides an auditable trail of all transactions, ensuring users have complete visibility into how the system operates. Regular third-party audits further enhance trust by confirming that every Lydia Coin is fully backed by fiat currencies or other assets.`
    },
    {
      title: `Participate in the Lydia Ecosystem`,
      desc: `Lydia Coin is more than just a digital currency. It’s part of a broader ecosystem that includes staking, governance participation, and yield farming. Users can stake their Lydia Coins to earn rewards or participate in the decentralized governance of the platform, helping to shape the future direction of Lydia Coin through a community-driven decision-making process.`
    },
  ]

  const benefitData = [
    {
      title: 'Stability through Asset-Backing',
      desc: 'Each Lydia Coin is backed by real-world assets like fiat currencies, ensuring price stability and making it a reliable option for users.',
    },
    {
      title: 'Low Transaction Fees',
      desc: 'Enjoy minimal fees for both small and large transactions, making Lydia Coin an affordable choice for everyday payments and business transfers.',
    },
    {
      title: 'Global Accessibility',
      desc: 'Lydia Coin offers borderless transactions, allowing users to send and receive money across the world quickly and easily without the need for traditional banking.',
    },
    {
      title: 'Enhanced Security',
      desc: 'Built on the Ethereum blockchain, Lydia Coin utilizes smart contracts and advanced encryption to ensure that every transaction is secure and verifiable.',
    },
    {
      title: 'Transparency & Trust',
      desc: 'With regular audits and a 1:1 reserve system, Lydia Coin provides users with full transparency, ensuring that each coin is always fully backed by its corresponding asset.',
    },
    {
      title: 'Fast & Efficient Transactions',
      desc: 'Unlike traditional banking systems, Lydia Coin processes transactions almost instantly, reducing wait times and improving overall efficiency for both personal and business uses.',
    },
  ]
  return (
    <>
      <HelmetComponent
        title="Lydia Coin - About"
        description="Learn more about Lydia Coin, its mission, and its team."
        keywords="Lydia Coin, About, Mission, Team"
        ogTitle="Lydia Coin - About"
        ogDescription="Learn more about Lydia Coin, its mission, and its team."
        ogImage="https://lydia-coin-landing-page.vercel.app"
        ogUrl="https://lydia-coin-landing-page.vercel.app"
        twitterTitle="Lydia Coin - About"
        twitterDescription="Learn more about Lydia Coin, its mission, and its team."
        twitterImage="https://lydia-coin-landing-page.vercel.app"
      />
      <section className="b-container-big w-full flex justify-center items-center pt-28 lg:pt-28 pb-20">
        <div className="flex flex-col max-w-[1100px] items-center gap-[10px]">
          <div className="text-center lydiaTitleContainer">
            About Us
          </div>
          <div className="text-subColor max-w-[986px] text-center">
            Lydia Coin (LYD) is transforming the digital payments space by offering a secure, scalable, and fiat-backed token. Our mission is to provide users with easy-to-use, borderless transactions that are both fast and affordable. Our experienced team of blockchain developers and financial experts is dedicated to building a secure and reliable system for eCommerce and beyond.
          </div>
        </div>
      </section>
      <section className="b-container-big w-full flex justify-center items-center pb-20">
        <div className="flex flex-col max-w-[1100px] items-center gap-[10px]">
          <div className="text-center lydiaTitleContainer">
            Who We Are?
          </div>
          <div className="text-subColor max-w-[986px] text-center">
            Lydia Coin (LYD) is a stablecoin designed to revolutionize the digital payments industry. Built on the foundation of security and scalability, Lydia Coin is backed by fiat currency, ensuring stability and trust in a volatile cryptocurrency market. We are a team of blockchain developers, financial experts, and payment innovators committed to offering seamless transactions for businesses and consumers across the globe.
          </div>
        </div>
      </section>
      <section className="b-container-big w-full flex justify-center items-center pb-20">
        <div className="flex flex-col max-w-[1100px] items-center gap-[10px]">
          <div className="text-center lydiaTitleContainer">
            Our Mission
          </div>
          <div className="text-subColor max-w-[986px] text-center">
            Our mission at Lydia Coin is to empower users with a reliable, fast, and low-cost digital payment solution. We aim to break down financial barriers by offering borderless transactions that are easy to use, enabling anyone, anywhere, to participate in the global digital economy with confidence.
          </div>
        </div>
      </section>
      <section className="b-container-big w-full flex justify-center items-center pb-20">
        <div className="flex flex-col max-w-[1100px] items-center gap-[10px]">
          <div className="text-center lydiaTitleContainer">
            Our Vision
          </div>
          <div className="text-subColor max-w-[986px] text-center">
            Our vision is to create a world where digital transactions are frictionless, accessible, and universally trusted. We envision Lydia Coin as a cornerstone of the future financial ecosystem, offering individuals and businesses the ability to manage and grow their assets with stability and transparency.
          </div>
        </div>
      </section>
      <section
        className="b-container-big pb-16 lg:pb-0 lg:justify-between m-auto"
        data-v-be2c11a6=""
      >
        <div
          className="lg:pt-[100px] md:max-w-[485px] lg:max-w-full !pt-0 m-auto"
          data-v-be2c11a6=""
        >
          <div
            className="flex flex-col justify-center items-center hidden lg:flex gap-[10px]"
            data-v-be2c11a6=""
          >
            <div className="lydiaSubtitleContainer" data-v-be2c11a6="">
              Decent Benefits for Every Party
            </div>
            <div className="text-center text-subColor max-w-[974px]  ">
              Lydia Coin offers a balanced and rewarding ecosystem for both private and institutional customers, ensuring transparency, accessibility, and customization.
            </div>
          </div>
          <div
            className="b-container-column flex flex-col lg:flex-row justify-center lg:justify-between mt-14"
            data-v-be2c11a6=""
          >
            <div
              className="text-subColor-1 mt-8 lg:mt-10 lg:w-full lg:max-w-[280px]"
              data-v-be2c11a6=""
            >
              <h2
                className="text-[#7D011C] text-2xl font-semibold"
                data-v-be2c11a6=""
              >
                Private Customers
              </h2>
              <div
                className="mt-5 flex items-center flex-row text-base"
                data-v-be2c11a6=""
              >
                <div className="mr-3" data-v-be2c11a6="">
                  <svg
                    data-v-be2c11a6=""
                    fill="none"
                    height="20"
                    viewBox="0 0 20 20"
                    width="20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_1828_943)">
                      <path
                        d="M18.3334 9.23257V9.99924C18.3323 11.7963 17.7504 13.5448 16.6745 14.9841C15.5985 16.4234 14.0861 17.4763 12.3628 17.9858C10.6395 18.4953 8.79774 18.4342 7.11208 17.8114C5.42642 17.1886 3.98723 16.0377 3.00915 14.5301C2.03108 13.0226 1.56651 11.2393 1.68475 9.44616C1.80299 7.65304 2.49769 5.94617 3.66525 4.58013C4.83281 3.21409 6.41068 2.26205 8.16351 1.86602C9.91635 1.46999 11.7502 1.65118 13.3917 2.38257"
                        stroke="#7D011C"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.66667"
                      />
                      <path
                        d="M18.3333 3.33398L10 11.6757L7.5 9.17565"
                        stroke="#7D011C"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.66667"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_1828_943">
                        <rect fill="white" height="20" width="20" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>{" "}
                Most transparent stablecoin
              </div>
              <div
                className="mt-3 flex items-center flex-row text-base"
                data-v-be2c11a6=""
              >
                <div className="mr-3" data-v-be2c11a6="">
                  <svg
                    data-v-be2c11a6=""
                    fill="none"
                    height="20"
                    viewBox="0 0 20 20"
                    width="20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_1828_943)">
                      <path
                        d="M18.3334 9.23257V9.99924C18.3323 11.7963 17.7504 13.5448 16.6745 14.9841C15.5985 16.4234 14.0861 17.4763 12.3628 17.9858C10.6395 18.4953 8.79774 18.4342 7.11208 17.8114C5.42642 17.1886 3.98723 16.0377 3.00915 14.5301C2.03108 13.0226 1.56651 11.2393 1.68475 9.44616C1.80299 7.65304 2.49769 5.94617 3.66525 4.58013C4.83281 3.21409 6.41068 2.26205 8.16351 1.86602C9.91635 1.46999 11.7502 1.65118 13.3917 2.38257"
                        stroke="#7D011C"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.66667"
                      />
                      <path
                        d="M18.3333 3.33398L10 11.6757L7.5 9.17565"
                        stroke="#7D011C"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.66667"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_1828_943">
                        <rect fill="white" height="20" width="20" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>{" "}
                Swap to any crypto
              </div>
              <div
                className="mt-3 flex items-center flex-row text-base"
                data-v-be2c11a6=""
              >
                <div className="mr-3" data-v-be2c11a6="">
                  <svg
                    data-v-be2c11a6=""
                    fill="none"
                    height="20"
                    viewBox="0 0 20 20"
                    width="20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_1828_943)">
                      <path
                        d="M18.3334 9.23257V9.99924C18.3323 11.7963 17.7504 13.5448 16.6745 14.9841C15.5985 16.4234 14.0861 17.4763 12.3628 17.9858C10.6395 18.4953 8.79774 18.4342 7.11208 17.8114C5.42642 17.1886 3.98723 16.0377 3.00915 14.5301C2.03108 13.0226 1.56651 11.2393 1.68475 9.44616C1.80299 7.65304 2.49769 5.94617 3.66525 4.58013C4.83281 3.21409 6.41068 2.26205 8.16351 1.86602C9.91635 1.46999 11.7502 1.65118 13.3917 2.38257"
                        stroke="#7D011C"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.66667"
                      />
                      <path
                        d="M18.3333 3.33398L10 11.6757L7.5 9.17565"
                        stroke="#7D011C"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.66667"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_1828_943">
                        <rect fill="white" height="20" width="20" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>{" "}
                Spend with bank cards
              </div>
              <div
                className="mt-3 flex items-center flex-row text-base"
                data-v-be2c11a6=""
              >
                <div className="mr-3" data-v-be2c11a6="">
                  <svg
                    data-v-be2c11a6=""
                    fill="none"
                    height="20"
                    viewBox="0 0 20 20"
                    width="20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_1828_943)">
                      <path
                        d="M18.3334 9.23257V9.99924C18.3323 11.7963 17.7504 13.5448 16.6745 14.9841C15.5985 16.4234 14.0861 17.4763 12.3628 17.9858C10.6395 18.4953 8.79774 18.4342 7.11208 17.8114C5.42642 17.1886 3.98723 16.0377 3.00915 14.5301C2.03108 13.0226 1.56651 11.2393 1.68475 9.44616C1.80299 7.65304 2.49769 5.94617 3.66525 4.58013C4.83281 3.21409 6.41068 2.26205 8.16351 1.86602C9.91635 1.46999 11.7502 1.65118 13.3917 2.38257"
                        stroke="#7D011C"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.66667"
                      />
                      <path
                        d="M18.3333 3.33398L10 11.6757L7.5 9.17565"
                        stroke="#7D011C"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.66667"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_1828_943">
                        <rect fill="white" height="20" width="20" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>{" "}
                Get passive yield in DeFi
              </div>
            </div>
            <img
              alt="Institutional crypto"
              className="order-first lg:order-none max-h-[355px] lg:max-h-full object-contain"
              data-v-be2c11a6=""
              src={AboutBenefit}
            />
            <div
              className="flex flex-col mt-6 order-first lg:hidden"
              data-v-be2c11a6=""
            >
              <div
                className="w-fit text-subColor-1 font-semibold text-[2rem]"
                data-v-be2c11a6=""
              >
                Decent benefits for every party
              </div>
            </div>
            <div
              className="mt-14 lg:mt-10 lg:ml-10 lg:w-full text-subColor-1 lg:max-w-[280px]"
              data-v-be2c11a6=""
            >
              <h2
                className="text-[#7D011C] text-2xl font-semibold"
                data-v-be2c11a6=""
              >
                Institutional Business
              </h2>
              <div
                className="mt-5 flex items-center flex-row text-base"
                data-v-be2c11a6=""
              >
                <div className="mr-3" data-v-be2c11a6="">
                  <svg
                    data-v-be2c11a6=""
                    fill="none"
                    height="20"
                    viewBox="0 0 20 20"
                    width="20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_1828_943)">
                      <path
                        d="M18.3334 9.23257V9.99924C18.3323 11.7963 17.7504 13.5448 16.6745 14.9841C15.5985 16.4234 14.0861 17.4763 12.3628 17.9858C10.6395 18.4953 8.79774 18.4342 7.11208 17.8114C5.42642 17.1886 3.98723 16.0377 3.00915 14.5301C2.03108 13.0226 1.56651 11.2393 1.68475 9.44616C1.80299 7.65304 2.49769 5.94617 3.66525 4.58013C4.83281 3.21409 6.41068 2.26205 8.16351 1.86602C9.91635 1.46999 11.7502 1.65118 13.3917 2.38257"
                        stroke="#7D011C"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.66667"
                      />
                      <path
                        d="M18.3333 3.33398L10 11.6757L7.5 9.17565"
                        stroke="#7D011C"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.66667"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_1828_943">
                        <rect fill="white" height="20" width="20" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>{" "}
                Transact unlimited amounts
              </div>
              <div
                className="mt-3 flex items-center flex-row text-base"
                data-v-be2c11a6=""
              >
                <div className="mr-3" data-v-be2c11a6="">
                  <svg
                    data-v-be2c11a6=""
                    fill="none"
                    height="20"
                    viewBox="0 0 20 20"
                    width="20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_1828_943)">
                      <path
                        d="M18.3334 9.23257V9.99924C18.3323 11.7963 17.7504 13.5448 16.6745 14.9841C15.5985 16.4234 14.0861 17.4763 12.3628 17.9858C10.6395 18.4953 8.79774 18.4342 7.11208 17.8114C5.42642 17.1886 3.98723 16.0377 3.00915 14.5301C2.03108 13.0226 1.56651 11.2393 1.68475 9.44616C1.80299 7.65304 2.49769 5.94617 3.66525 4.58013C4.83281 3.21409 6.41068 2.26205 8.16351 1.86602C9.91635 1.46999 11.7502 1.65118 13.3917 2.38257"
                        stroke="#7D011C"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.66667"
                      />
                      <path
                        d="M18.3333 3.33398L10 11.6757L7.5 9.17565"
                        stroke="#7D011C"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.66667"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_1828_943">
                        <rect fill="white" height="20" width="20" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>{" "}
                Zero conflict of interests
              </div>
              <div
                className="mt-3 flex items-start flex-row justify-start text-base"
                data-v-be2c11a6=""
              >
                <div className="mr-3" data-v-be2c11a6="">
                  <svg
                    data-v-be2c11a6=""
                    fill="none"
                    height="20"
                    viewBox="0 0 20 20"
                    width="20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_1828_943)">
                      <path
                        d="M18.3334 9.23257V9.99924C18.3323 11.7963 17.7504 13.5448 16.6745 14.9841C15.5985 16.4234 14.0861 17.4763 12.3628 17.9858C10.6395 18.4953 8.79774 18.4342 7.11208 17.8114C5.42642 17.1886 3.98723 16.0377 3.00915 14.5301C2.03108 13.0226 1.56651 11.2393 1.68475 9.44616C1.80299 7.65304 2.49769 5.94617 3.66525 4.58013C4.83281 3.21409 6.41068 2.26205 8.16351 1.86602C9.91635 1.46999 11.7502 1.65118 13.3917 2.38257"
                        stroke="#7D011C"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.66667"
                      />
                      <path
                        d="M18.3333 3.33398L10 11.6757L7.5 9.17565"
                        stroke="#7D011C"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.66667"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_1828_943">
                        <rect fill="white" height="20" width="20" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>{" "}
                API and white-label solutions
              </div>
              <div
                className="mt-3 flex items-start text-base"
                data-v-be2c11a6=""
              >
                <div className="mr-3" data-v-be2c11a6="">
                  <svg
                    data-v-be2c11a6=""
                    fill="none"
                    height="20"
                    viewBox="0 0 20 20"
                    width="20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_1828_943)">
                      <path
                        d="M18.3334 9.23257V9.99924C18.3323 11.7963 17.7504 13.5448 16.6745 14.9841C15.5985 16.4234 14.0861 17.4763 12.3628 17.9858C10.6395 18.4953 8.79774 18.4342 7.11208 17.8114C5.42642 17.1886 3.98723 16.0377 3.00915 14.5301C2.03108 13.0226 1.56651 11.2393 1.68475 9.44616C1.80299 7.65304 2.49769 5.94617 3.66525 4.58013C4.83281 3.21409 6.41068 2.26205 8.16351 1.86602C9.91635 1.46999 11.7502 1.65118 13.3917 2.38257"
                        stroke="#7D011C"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.66667"
                      />
                      <path
                        d="M18.3333 3.33398L10 11.6757L7.5 9.17565"
                        stroke="#7D011C"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.66667"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_1828_943">
                        <rect fill="white" height="20" width="20" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>{" "}
                Internet speed & 24/7/365 accessibility
              </div>
              <div
                className="flex items-center flex-col justify-center z-50 fixed inset-0 overflow-hidden"
                data-v-be2c11a6=""
                style={{
                  display: "none",
                }}
                title="Make a request"
              >
                <div className="absolute inset-0 bg-[#003A604D] overflow-hidden from-gray-700 via-gray-900 to-gray-700" />
                <div
                  className="max-h-modal w-11/12 md:w-3/5 lg:w-2/5 xl:w-4/12 z-50 bg-white rounded-[6px] text-subColor"
                  style={{
                    display: "none",
                  }}
                >
                  <svg
                    className="ml-auto mt-4 mr-4 cursor-pointer"
                    fill="none"
                    height="25"
                    viewBox="0 0 24 25"
                    width="24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M18 6.5L6 18.5"
                      stroke="#97A6C1"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                    />
                    <path
                      d="M6 6.5L18 18.5"
                      stroke="#97A6C1"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                    />
                  </svg>
                  <div className="pb-8 px-8" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="md:pb-[56px] pb-[64px] bg-grayBackground lg:pt-[186.5px]"
        data-v-be2c11a6=""
      >
        <div
          className=" b-container-big flex flex-col lg:flex-row items-center justify-between"
          data-v-be2c11a6=""
        >
          <div
            className="b-container-column mb-10  flex justify-center items-start  md:max-w-full lg:w-2/4"
            data-v-be2c11a6=""
          >
            <h2
              className="lydiaSubtitleContainer flex flex-col w-fit"
              data-v-be2c11a6=""
            >
              What Defines Lydia?
            </h2>
          </div>
          <div
            className="lg:mt-0 mt-0 md:w-2/4 md:flex md:justify-between md:m-0 m-auto"
            data-v-be2c11a6=""
          >
            <div
              className="b-container-column  md:max-w-[485px] lg:max-w-max justify-center m-auto"
              data-v-be2c11a6=""
            >
              <div
                className="flex flex-col justify-center items-center lg:flex-row"
                data-v-be2c11a6=""
              >
                <div
                  className="mb-[60px] lg:mb-0 lg:bg-white py-5 flex flex-col md:w-1/2 items-center lg:rounded-[1.6rem]  "
                  data-v-be2c11a6=""
                >
                  <div
                    className="  lg:max-h-[32rem] flex justify-center items-center"
                    data-v-be2c11a6=""
                  >
                    <img
                      alt="CBDC"
                      data-v-be2c11a6=""
                      src={AboutDefine1}
                      className="w-full"
                    />
                  </div>
                  <div
                    className="lg:px-5 text-subColor mt-10"
                    data-v-be2c11a6=""
                  >
                    <h3
                      className="text-[#7D011C] text-2xl font-semibold"
                      data-v-be2c11a6=""
                    >
                      Worldwide Reach
                    </h3>
                    <div className=" mt-3 lg:mt-2 text-base" data-v-be2c11a6="">
                      Ensure your assets are protected with our inheritance
                      feature...
                    </div>
                  </div>
                </div>
                <div
                  className="lg:bg-white py-5 lg:rounded-2xl lg:ml-4 mb-[60px] lg:mb-0 w-full md:w-1/2"
                  data-v-be2c11a6=""
                >
                  <div
                    className="lg:max-h-[32rem]  flex items-center justify-center max-h-[32rem]"
                    data-v-be2c11a6=""
                  >
                    <img
                      alt="Legal euro stablecoin"
                      data-v-be2c11a6=""
                      src={AboutDefine2}
                    />
                  </div>
                  <div
                    className="lg:px-5 text-subColor mt-10"
                    data-v-be2c11a6=""
                  >
                    <h3
                      className="text-[#7D011C] text-2xl font-semibold"
                      data-v-be2c11a6=""
                    >
                      Proven Legacy
                    </h3>
                    <div className=" mt-3 lg:mt-2 text-base" data-v-be2c11a6="">
                      Protect your wealth from inflation by storing it in Lydia
                      Stable Coin
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="flex flex-col justify-center items-center lg:flex-row lg:mt-8"
                data-v-be2c11a6=""
              >
                <div
                  className="gradientBorder lg:rounded-[18px] mb-[60px] lg:mb-0  md:w-1/2 w-full"
                  data-v-be2c11a6=""
                >
                  <div
                    className="ml-[2px] mr-[2px] py-5 lg:bg-white rounded-b-[1.6rem] rounded-t-[1.6rem] flex flex-col "
                    data-v-be2c11a6=""
                  >
                    <div
                      className="lg:max-h-[32rem] flex justify-center items-center"
                      data-v-be2c11a6=""
                    >
                      <img
                        alt="Central bank crypto"
                        data-v-be2c11a6=""
                        src={AboutDefine3}
                      />
                    </div>
                    <div
                      className="lg:px-5 mt-10 text-subColor"
                      data-v-be2c11a6=""
                    >
                      <h3
                        className="text-[#7D011C] text-2xl font-semibold"
                        data-v-be2c11a6=""
                      >
                        Unrivalled Safety of Funds
                      </h3>
                      <div
                        className="mt-3 lg:mt-2 text-base"
                        data-v-be2c11a6=""
                      >
                        Use Lydia Wallet to purchase Lydia Coins
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="lg:bg-white py-5 flex flex-col items-center lg:flex-grow lg:max-w-[592px] rounded-[1.6rem] lg:ml-4 w-full md:w-1/2 "
                  data-v-be2c11a6=""
                >
                  <div
                    className=" lg:max-h-[32rem] flex items-center"
                    data-v-be2c11a6=""
                  >
                    <img
                      alt="Lyd stabelcoin"
                      data-v-be2c11a6=""
                      src={AboutDefine4}
                    />
                  </div>
                  <div
                    className="lg:px-5 mt-10 text-subColor w-full md:max-w-max"
                    data-v-be2c11a6=""
                  >
                    <h3
                      className="text-[#7D011C] text-2xl font-semibold"
                      data-v-be2c11a6=""
                    >
                      No Exposure to USD Risks
                    </h3>
                    <div className=" mt-3 lg:mt-2 text-base" data-v-be2c11a6="">
                      Use Lydia Wallet to purchase Lydia Coins
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <AboutDesc title='The Roadmap for Developing Lydia Coin'
        desc={`Clear, strategic milestones drive the development and expansion of Lydia Coin, as mentioned below:`}
      />
      <AboutDesc title='Why Choose Lydia Coin?'
        desc={`Lydia Coin stands out in the digital currency space by offering a stable, secure, and versatile platform that combines the best of blockchain technology with the assurance of real-world asset backing. Whether you're a business looking for low-cost transactions or an individual seeking a reliable store of value, Lydia Coin provides an innovative solution designed for modern financial needs.`}
      />
      <AboutDesc title='How Does It Work?'
        desc={`Lydia Coin is designed to offer a simple, secure, and efficient way to engage in digital transactions. By combining the reliability of fiat-backed reserves with the innovation of blockchain technology, Lydia Coin provides a user-friendly experience for both individuals and businesses.`}
      />
      <section className="b-container-big w-full flex justify-center items-center pb-10">
        <div className="flex flex-col max-w-[1100px] gap-[10px]">
          <div className="text-lg font-bold">
            Here’s a step-by-step breakdown of how Lydia Coin operates:
          </div>
        </div>
      </section>
      {workData?.map((item, idx) =>
        <AboutList title={item?.title} desc={item?.desc} id={idx + 1} />
      )}
      <AboutDesc title='Benefits of Using Lydia Coin'
        desc={`Lydia Coin brings a unique blend of stability, security, and innovation to the world of digital payments. As a fiat-backed digital currency, it combines the reliability of traditional financial systems with the speed, transparency, and low costs associated with blockchain technology. Whether you're an individual looking for a secure store of value or a business seeking efficient cross-border transactions, Lydia Coin offers a solution tailored to modern financial needs.`}
      />
      <section className="b-container-big w-full flex justify-center items-center pb-10">
        <div className="flex flex-col max-w-[1100px] gap-[10px]">
          <div className="text-lg font-bold">
            Here are six key benefits of using Lydia Coin:
          </div>
        </div>
      </section>
      {benefitData?.map((item, idx) =>
        <AboutList title={item?.title} desc={item?.desc} id={idx + 1} />
      )}
       <AboutDesc title='The Lydia Coin Ecosystem'
        desc={`The Lydia Coin ecosystem is built to offer a seamless and secure platform for users to manage, trade, and grow their digital assets. By combining the power of blockchain technology with user-friendly applications, Lydia Coin ensures that every transaction is secure, verifiable, and efficient. The ecosystem supports various activities, such as payments, staking, governance participation, and cross-chain functionalities, making it a versatile digital currency solution for both individuals and businesses.`}
      />
       <AboutDesc title='What Blockchain Are We Using?'
        desc={`Lydia Coin operates on the Ethereum Blockchain, one of the most secure and trusted decentralized platforms in the world. To ensure fast, scalable, and cost-effective transactions, we utilize a Layer 3 solution that enhances the efficiency of the Ethereum network while maintaining its robust security.`}
        wrapperClass='pb-5'
      />
      <AboutDesc title=''
        desc={`Lydia Coin's blockchain solution offers several advantages, including notably lower transaction fees, rapid processing times, and exceptional scalability. These features ensure that users can conduct transactions efficiently without facing high costs, all while enjoying a network designed to handle increased activity smoothly. This robust framework supports both everyday transactions and larger-scale operations, making it an ideal choice for businesses and individuals alike.`}
        wrapperClass='pb-5'
     />
       <AboutDesc title=''
        desc={`By integrating Layer 3 technology, Lydia Coin provides a more efficient user experience, eliminating the common issues associated with congestion and high fees on Layer 1 blockchains.`}
        wrapperClass='pb-5'
     />
       <AboutDesc title=''
        desc={`This approach ensures that Lydia Coin maintains all the benefits of Ethereum's proven infrastructure while delivering a superior transaction experience that supports mass adoption and widespread use for payments, transfers, and staking.`}
        wrapperClass='pb-5'
      />
      <JoinLydia />
      {/* <AboutDesc title='Meet Our Team'
        desc={`Our team consists of industry veterans and experts in blockchain technology, finance, and payment systems. From seasoned developers to experienced financial strategists, we work collaboratively to ensure Lydia Coin remains a leader in the digital payments space.`}
      /> */}
    </>
  );
};
export default About;
