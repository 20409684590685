import { useState } from "react";

interface TabData {
  id: string;
  title: string;
  subTitle: string;
  content: string[];
}

interface TabsProps {
  tabs: TabData[];
  setSubTitle: (subTitle: string) => void;
}

const Tabs: React.FC<TabsProps> = ({ tabs, setSubTitle }) => {
  const uniqueTitles = Array.from(new Set(tabs.map((tab) => tab.title)));
  const [activeTab, setActiveTab] = useState<string>(tabs[0]?.id || "");

  return (
    <div className="flex flex-col md:flex-row gap-[3.8rem] md:gap-[7rem] ">
      <div className="mb-3 md:mb-0 md:w-1/5">
        <ul className="flex flex-col space-y-2 border-r border-gray-300">
          {uniqueTitles.map((title) => (
            <div key={title}>
              {/* <div className="px-2 py-1 text-[#0f2b43] font-bold">{title}</div> */}
              {tabs
                .filter((tab) => tab.title === title)
                .map((tab) => (
                  <li key={tab.id} className="px-2 py-2">
                    <button
                      className={`text-left py-2 px-2 ${
                        activeTab === tab.id
                        ? "border-l-2 border-[#0f2b43] text-[#7D011C]"
                          : "text-gray-400"
                      } focus:outline-none transition-colors duration-300  `}
                      onClick={() => {
                        setActiveTab(tab.id);
                        setSubTitle(tab.subTitle);
                      }}
                      role="tab"
                      aria-controls={tab.id}
                      aria-selected={activeTab === tab.id}
                    >
                      {tab.subTitle}
                    </button>
                  </li>
                ))}
            </div>
          ))}
        </ul>
      </div>

      <div className="md:w-4/5">
        <div className="tab-content">
          {tabs.map((tab) => (
            <div
              key={tab.id}
              className={`${
                activeTab === tab.id ? "block" : "hidden"
              } text-left text-gray-500`}
              id={tab.id}
              role="tabpanel"
              aria-labelledby={`${tab.id}-tab`}
            >
              {/* <h3 className="text-xl font-bold">{tab.subTitle}</h3> */}
              <ul className="list-disc text-subColor-1">
                {tab.content.map((item, index) => (
                  <p className="mb-4" key={index}>
                    {item}
                  </p>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Tabs;
