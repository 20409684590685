import React from 'react';

interface ListData {
  title: string;
  desc: string;
  id: number;
}

// interface AboutListProps {
//   data: ListData[];
// }

const AboutList: React.FC<ListData> = ({ title='',desc='',id=1 }) => {
  return (
    <section className="b-container-big w-full flex justify-center items-center pb-10">
        <div className="flex flex-col max-w-[1100px] gap-[10px]">
          <div className="text-lg font-bold">
           {id}. {title}
          </div>
          <div className="text-subColor text-sm max-w-[986px] leading-6">
            {desc}
          </div>
        </div>
    </section>
  );
};

export default AboutList;
